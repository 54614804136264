import { NavItem } from '@/components/layout/nav-item'
import { checkEnv } from '../lib/utils/utils'


const SITE = checkEnv(process.env.NEXT_PUBLIC_SITE, 'NEXT_PUBLIC_SITE') // jbda | dd
const SITE_URL = checkEnv(process.env.NEXT_PUBLIC_SITE_URL, 'NEXT_PUBLIC_SITE_URL')
const SITE_JBDA_URL = process.env.NEXT_PUBLIC_SITE_JBDA_URL || '/jbda'
const SITE_DD_URL = process.env.NEXT_PUBLIC_SITE_DD_URL || '/dd'
const GTM_ID_JBDA =  process.env.NEXT_PUBLIC_JBDA_GTM_ID
const GTM_ID_DD = process.env.NEXT_PUBLIC_DD_GTM_ID
const HOW_TO_YOUTUBE_VIDEO_EN = process.env.NEXT_PUBLIC_YOUTUBE_VIDEO_EN
const HOW_TO_YOUTUBE_VIDEO_FR = process.env.NEXT_PUBLIC_YOUTUBE_VIDEO_FR

const JBDA_NAV_ITEMS: NavItem[] = []

const DD_NAV_ITEMS: NavItem[] = [
  { title: { en: 'How it works', fr: 'Comment ça marche' }, href: '/#howItWorks' },
  { title: { en: 'Use cases', fr: 'Études de cas' }, href: '/#useCases' }
]

/**
 * Site Config for JBDA SITE
 */
export const siteConfigJBDA = {
  appName: 'JBDA',
  descriptionHtml: 'JBDA : Simplifier l’investissement crypto dans l’économie réelle. - Sécurisez Vos Transactions Immobilières et Mobilières en investissant sereinement vos Crypto-Monnaies dans l\'économie réelle.',
  links: {
    linkedin: 'https://www.linkedin.com/company/78410287',
    twitter: 'https://twitter.com/JarvixUnchained'
  },
  logo: {
    app: '/jbda/JBDA_logo_picto_couleur_rvb.png',
    appWhite: '/jbda/JBDA_logo_picto_blanc_rvb.png',
    vertical: '/jbda/JBDA_logo_vertical_couleur_rvb.png',
    verticalWhite: '/jbda/JBDA_logo_vertical_blanc_rvb.png',
    header: '/jbda/JBDA_logo_horizontal_couleur_rvb.png',
    headerWhite: '/jbda/JBDA_logo_horizontal_blanc_rvb.png'
  },
  mailSubjectPreffix: '[JBDA]',
  siteUrl: SITE_JBDA_URL,
  gtmId: GTM_ID_JBDA,
  consentCookie:'jbda-consent',
  navItems: JBDA_NAV_ITEMS
}

/**
 * Site Config for DD SITE
 */
export const siteConfigDD = {
  appName: 'Digital Deposit',
  descriptionHtml: 'Digital Deposit : Sécurisez Vos Transactions Immobilières et Mobilières en investissant sereinement vos Crypto-Monnaies dans l\'économie réelle.',
  links: {
    linkedin: 'https://www.linkedin.com/company/78410287',
    twitter: 'https://twitter.com/JarvixUnchained'
  },
  logo: {
    app: '/dd/DD_logo_picto_couleur_rvb.png',
    appWhite: '/dd/DD_logo_picto_blanc_rvb.png',
    vertical: '/dd/DD_logo_vertical_couleur_rvb.png',
    verticalWhite: '/dd/DD_logo_vertical_blanc_rvb.png',
    header: '/dd/DD_logo_horizontal_couleur_rvb.png',
    headerWhite: '/dd/DD_logo_horizontal_blanc_rvb.png'
  },
  mailSubjectPreffix: '[DD]',
  siteUrl: SITE_DD_URL,
  gtmId: GTM_ID_DD,
  consentCookie:'dd-consent',
  navItems: DD_NAV_ITEMS
}

// Select the SITE CONFIG based on the SITE env var
const SITE_CONFIG = SITE === 'jbda' ? siteConfigJBDA : siteConfigDD

/**
 * Site Config
 */
export const siteConfig = {
  appId: 'jbda-dd',
  appName: SITE_CONFIG.appName,
  descriptionHtml: SITE_CONFIG.descriptionHtml,
  links: {
    github: 'https://github.com/jarvix-src/jvx-template',
    linkedin: SITE_CONFIG.links.linkedin,
    twitter: SITE_CONFIG.links.twitter
  },
  logo: {
    app: SITE_CONFIG.logo.app,
    appWhite: SITE_CONFIG.logo.appWhite,
    vertical: SITE_CONFIG.logo.vertical,
    verticalWhite: SITE_CONFIG.logo.verticalWhite,
    header: SITE_CONFIG.logo.header,
    headerWhite: SITE_CONFIG.logo.headerWhite,
    jarvix: '/jarvix_logo_264x264_transparent.png',
    jarvixVertical: '/jarvix_logo_rvb_1a_vertical_couleur.png',
    jarvix64: '/_next/image?url=%2Fjarvix_logo_264x264_transparent.png&w=64&q=75',
    bdVertical: '/logo-bruzzodubucq.png'
  },
  mailSubjectPreffix: SITE_CONFIG.mailSubjectPreffix,
  contactAddress: 'contact@jbda.io',
  jarvixContactAddress: 'contact@jarvix.com',
  privacyAddress: 'contact+privacy@jbda.io',
  siteJarvixUrl: 'https://jarvix.com',
  siteUrl: SITE_URL,
  gtmId: SITE_CONFIG.gtmId,
  consentCookie: SITE_CONFIG.consentCookie,
  navItems: SITE_CONFIG.navItems,
  videoUrlEmbeddedEn: 'https://www.youtube-nocookie.com/embed/' + HOW_TO_YOUTUBE_VIDEO_EN,
  videoUrlEmbeddedFr: 'https://www.youtube-nocookie.com/embed/' + HOW_TO_YOUTUBE_VIDEO_FR
}
